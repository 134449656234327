html,
body {
  margin: 0;
  height: 100%;
}

/* #root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
} */

.body {
  max-width: 850px;
  margin: auto;
}

.App {
  /* text-align: center; */
  /* display: flex;
  width: 100%; */
  box-sizing: border-box;
  /* max-width: 1200px; */
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
}

.active-icon {
  /* Styles for the active icon, e.g., increased size */
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.icon-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.emoji-icon-sm {
  display: flex;
  align-items: flex-start;
  margin-top: -6px;
  font-size: 1.3em;
}

.emoji-icon {
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  font-size: 2em;
}

.emoji-icon-white {
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  font-size: 1.5em;
  color: white;
  filter: brightness(0) invert(1) saturate(0%) contrast(150%);
}

.heading {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.card-sm {
  padding: 0px;
  width: 100%;
  max-width: 280px;
  gap: 10px;
}

.card-book {
  padding: 0px;
  width: 100%;
  min-width: 240px;
  max-width: 280px;
  gap: 10px;
}

.card {
  padding: 0px;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  gap: 10px;
}

.card-lg {
  /* box-sizing: border-box; */
  padding: 0px 0px 0px 0px;
  width: 450px;
  min-width: 300px;
  gap: 10px;
}

.card-md {
  width: 350px;
}

@media (max-width: 767px) {
  .card-lg {
    width: 100%;
  }
}

.card-yt {
  padding: 0px 5px 0px 0px;
  width: 100%;
  max-width: 350px;
  min-width: 300px;
  gap: 10px;
}

.card-polaroid {
  padding: 0px;
  width: 97%;
  max-width: 330px;
  min-width: 330px;
  /* padding-left: 15px; */
  gap: 10px;
}

.card-container-scroll {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-height: 515px;
  /* max-width: 100%; */
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.card-container-scroll-1 {
  display: flex;
  max-height: 525px;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.card-container-carousel {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-content: flex-start;
  width: 100%;
  max-height: 600px;
  gap: 10px;
  padding-top: 15px;
}

.navbar a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  padding: 10px;
  font-size: 16px;
  color: slategray;
  text-align: center;
  text-decoration: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.repo-language {
  display: inline-flex;
  align-items: center;
  /* border: 1px solid black; */
  font-size: 14px;
  margin: 0px 10px;
  color: var(--color-fg-muted);
}

:root {
  --color-primer-border-contrast: rgba(27, 31, 36, 0.1);
}

.repo-language-color {
  position: relative;
  /* top: 1px; */
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid var(--color-primer-border-contrast);
  border-radius: 50%;
  margin-right: 5px;
}

.language-text {
  font-size: 14px;
}

/* .body { */
/* margin-top: 60px; */
/* margin: 40px;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  color: slategray;
  min-height: 100vh; */
/* } */

/* p {
    max-width: 800px;
    min-width: 200px;
    margin: 2px;
    color: black;
}

p a {
  max-width: 800px;
  min-width: 200px;
  margin: 2px;
  color: #404040;
}

h1 {
    font-size: 20px;
    color: slategray;
    font-family: poppins, sans-serif;
}

h4 {
  color: #404040;
  font-family: poppins, sans-serif;
}  */
